import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
//import { Provider } from "react-redux";
import "./i18n.js";
//import { GoogleOAuthProvider } from "@react-oauth/google";

import Login from "./Pages/Login/Login.js";
import TermsAndConditions from "./Pages/Login/TermsAndConditions.js";
import Loader from "./Pages/Loader/Loader.js"; 
import Unauthorized from "./Pages/Unauthorized/Unauthorized.js";
import ResetPassword from "./Pages/ResetPassword/ResetPassword.js";
import Maintenance from "./Pages/Maintenance/Maintenance.js";
import ThankYou from "./Pages/Thank/ThankYou.js";
import Cancel from "./Pages/Cancel/Cancel.js";


// Fonction pour corriger une URL mal formée
function fixMalformedUrl() {
  if (window.location.pathname.includes("&") && !window.location.search) {
    const [basePath, ...queryParams] = window.location.pathname.split("&");
    const queryString = "?" + queryParams.join("&");

    // Remplace l'URL mal formée par une version correcte
    window.history.replaceState({}, "", `${basePath}${queryString}`);
  }
}

// Exécuter la correction avant que React Router ne charge
fixMalformedUrl();


function App() {
  console.log(`REACT_APP_BACKEND_BASE_URL ${process.env.REACT_APP_BACKEND_BASE_URL}`);
  return (
   // <Provider store={store}>
      //<GoogleOAuthProvider clientId="386932037035-k8v833noqjk7m4***********.apps.googleusercontent.com">
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            {/* <Layout> */}
              <Routes>
              <Route path="/" element={<Navigate to="/login" replace />} />
                <Route path="/login" element={<Login />} />
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/maintenance" element={<Maintenance />} />
                <Route path="/thank/*" element={<ThankYou />} />
                <Route path="/cancel/*" element={<Cancel />} />
              </Routes>
            {/* </Layout> */}
          </BrowserRouter>
        </Suspense>
      //</GoogleOAuthProvider>
   // </Provider>
  );
}

export default App;
