import React, { useEffect } from "react";
import "./Cancel.css";
import { useTranslation } from "react-i18next";

function Cancel() {
  const { t } = useTranslation("Cancel");


  return (
    <div className="canceled-container">
      <h2>{t("you_have_been_redirected")}</h2>
      <p>{t("payment_canceled")}</p>
      <p>{t("you_can_go_to_the_previous_page")}</p>
    </div>
  );
}

export default Cancel;
