import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./PaymentPage.css";

const PaymentPage = () => {
  const { t } = useTranslation("Payment");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const validateInputs = () => {
    if (fullName.trim().split(/\s+/).length < 2) {
      setError(t("full_name_error"));
      return false;
    }

    const phoneRegex = /^05\d{8}$/;
    if (!phoneRegex.test(phone)) {
      setError(t("phone_error"));
      return false;
    }

    if (!email.trim()) {
      setError(t("email_required"));
      return false;
    }

    return true;
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    setError("");

    if (!validateInputs()) return;

    setLoading(true);
    const orderNumber = Math.floor(Math.random() * 1000000);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/create-payment`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ fullName, phone, email, orderNumber }),
      });

      const data = await response.json();

      if (data.status === 0) {
        setError(data.err?.message || t("payment_failed"));
      } else if (data.data?.url) {
        window.open(data.data.url, "_blank");
      } else {
        setError(t("server_error"));
      }
    } catch (err) {
      console.error("Payment error:", err);
      setError(t("server_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="payment-container">
      <h2>{t("complete_payment")}</h2>
      <p>{t("payment_description")}</p>
      <p className="redirect-notice">{t("redirect_notice")}</p>
      <form onSubmit={handlePayment} className="payment-form">
        <div className="form-group">
          <label>{t("full_name")}</label>
          <input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="John Doe"
            required
          />
        </div>
        <div className="form-group">
          <label>{t("phone")}</label>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="0584224416"
            required
          />
        </div>
        <div className="form-group">
          <label>{t("email")}</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="test@gmail.com"
            required
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="pay-button" disabled={loading}>
          {loading ? t("processing") : t("pay_now")}
        </button>
      </form>
    </div>
  );
};

export default PaymentPage;
