import React, { useEffect } from "react";
import "./ThankYou.css";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

function ThankYou() {
  const { t } = useTranslation("Thank");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const response = searchParams.get("response"); // Get the response from URL
    if (response === "success") {
      localStorage.setItem("paymentSuccess", "true"); // Store success flag
    }
  }, [searchParams]);

  return (
    <div className="thankyou-container">
      <h2>{t("thank_you")}</h2>
      <p>{t("payment_completed")}</p>
      <p>{t("you_can_go_to_the_previous_page")}</p>
    </div>
  );
}

export default ThankYou;
