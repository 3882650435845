import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./LoginSignUp.css";
import { useTranslation } from "react-i18next";
import PaymentPage from "../../components/PaymentPage/PaymentPage";

function LoginSignUp() {
  const [activeTab, setActiveTab] = useState(0);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [businessField, setBusinessField] = useState("");
  const [establishmentDate, setEstablishmentDate] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [error, setError] = useState("");
  const [isSecondStep, setIsSecondStep] = useState(false);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [organizationCode, setOrganizationCode] = useState("");
  const [generatedCode, setGeneratedCode] = useState(""); // For admin: code to share with employees
  const [role, setRole] = useState("employee"); // Default role is employee
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [signupTriggered, setSignupTriggered] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneRegion, setPhoneRegion] = useState("+972"); //default region is israel
  const { t } = useTranslation("Login");
  const [emailSentMessages, setEmailSentMessages] = useState([]);
  const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL || "http://localhost:5000";
  // Region options for phone number
  const regionOptions = [
    { code: "+972", name: "Israel" },
    { code: "+1", name: "USA/Canada" },
    { code: "+44", name: "UK" },
    { code: "+33", name: "France" },
    { code: "+49", name: "Germany" },
    { code: "+39", name: "Italy" },
    { code: "+34", name: "Spain" },
    // Add more countries as needed
  ];

  // Listen for the payment completed event from PaymentPage
  useEffect(() => {
    const checkPaymentSuccess = () => {
      if (localStorage.getItem("paymentSuccess") === "true") {
        setPaymentCompleted(true);
        setShowPaymentPopup(false);
        localStorage.removeItem("paymentSuccess"); // Nettoyage du flag

        // Récupérer les données d'inscription sauvegardées
        const storedData = JSON.parse(localStorage.getItem("signupData"));
        console.log("✅ Data retrieved from localStorage:", storedData);

        if (storedData) {
          setFullName(storedData.fullName);
          setEmail(storedData.email);
          setPassword(storedData.password);
          setBusinessField(storedData.businessField);
          setEstablishmentDate(storedData.establishmentDate);
          setCompanySize(storedData.companySize);
          setRole(storedData.role);
          setOrganizationName(storedData.organizationName);
          setOrganizationCode(storedData.organizationCode);
          if (storedData.phoneNumber) setPhoneNumber(storedData.phoneNumber);
          if (storedData.phoneRegion) setPhoneRegion(storedData.phoneRegion);
        } else {
          console.error("❌ No signup data found in localStorage!");
        }

        localStorage.removeItem("signupData"); // Nettoyage des données après récupération
      }
    };

    window.addEventListener("storage", checkPaymentSuccess);
    checkPaymentSuccess(); // Vérifier immédiatement si le flag est déjà mis

    return () => {
      window.removeEventListener("storage", checkPaymentSuccess);
    };
  }, []);

  //complet signup after payment
  useEffect(() => {
    if (paymentCompleted && !signupTriggered) {

      // Attendre un peu que React mette bien à jour l'état avant de lancer completeSignUp()
      setTimeout(() => {
        setSignupTriggered(true); // Empêche un deuxième appel
        completeSignUp();
      }, 500); // Petit délai pour être sûr que les valeurs sont bien mises à jour
    }
    localStorage.removeItem("signupData");

  }, [paymentCompleted, signupTriggered]);


  // Utility to update the <base> tag (if needed)
  const updateBaseTag = (baseURL) => {
    const baseTag = document.querySelector("base") || document.createElement("base");
    baseTag.href = baseURL;
    if (!document.head.contains(baseTag)) {
      document.head.appendChild(baseTag);
    }
  };

  // Change between tabs (Sign Up / Login)
  const handleTabChange = (newValue) => {
    setError("");
    setActiveTab(newValue);
  };

  // When the role changes, réinitialiser les champs spécifiques
  const handleRoleChange = (e) => {
    setRole(e.target.value);
    setOrganizationName("");
    setOrganizationCode("");
  };

  // Call backend to check if the user (email, username) already exists
  const checkUserExists = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/check-user`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, username: fullName }),
      });
      const data = await response.json();
      if (!response.ok) {
        setError(t(data.msgKey || "User validation error"));
        return false;
      }
      return true;
    } catch (error) {
      console.error("Error checking user existence:", error);
      setError(t("An error occurred during validation."));
      return false;
    }
  };

  // Handle login submit
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/login`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username: fullName, password }),
          credentials: "include",
        }
      );

      const data = await response.json();

      if (response.ok) {
        updateBaseTag(`${process.env.REACT_APP_BACKEND_BASE_URL}/frontend`);
        navigate("/frontend/");
      } else {
        setError(t(data.msgKey || "genericError"));
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError(t("Login failed. Please try again."));
    }
  };

  // Handle sign-up submit
  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // First step : basic info (name, email, password, role)
    if (!isSecondStep) {
      // You could also perform client‐side validations here
      const isValid = await checkUserExists();
      if (!isValid) return;

      setIsSecondStep(true);
      return;
    }

    // Second step : additional info (business, dates, company size, organization)
    // For admin, we display the payment popup before completing registration
    // Second step : additional info (business, dates, company size, organization)
    if (role === "admin" && !paymentCompleted) {
      // Sauvegarde des données avant de rediriger vers le paiement
      localStorage.setItem("signupData", JSON.stringify({
        fullName, email, password, businessField, establishmentDate, companySize, role,
        organizationName: role === "admin" ? organizationName : "",
        organizationCode: role === "employee" ? organizationCode : "",
        phoneNumber, // Save phone number
        phoneRegion // Save phone region
      }));

      setShowPaymentPopup(true);
      return; // Ne continue pas tant que le paiement n'est pas fait
    }


    completeSignUp();
  };

  // Final registration call to the backend
  const completeSignUp = async () => {
    console.log("Completing sign-up...");
    try {
      const payload = {
        username: fullName,
        email,
        password,
        businessField,
        establishmentDate,
        companySize,
        role,
        organizationName: role === "admin" ? organizationName : undefined,
        organizationCode: role === "employee" ? organizationCode : undefined,
      };
      if (role === "admin" && phoneNumber) {
        payload.phoneNumber = `${phoneRegion}${phoneNumber}`;
      }

      console.log("Submitting registration form:", payload);

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/register`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
          credentials: "include",
        }
      );

      const data = await response.json();
      console.log("Server response:", response.status, data);

      if (response.ok) {
        // For admin, we expect a generated organization code from the backend
        if (role === "admin") {
          setGeneratedCode(data.code);
        }
        setShowPopup(true);
      } else {
        setError(t(data.msgKey || "registration.genericError"));
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setError(t("registration.genericError"));
    }
  };

  // Close the registration popup and switch to the Login tab
  const closePopup = () => {
    setShowPopup(false);
    setActiveTab(1);
  };

  // Handle forgot password
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/forgot-password`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: resetEmail }),
        }
      );

      if (response.ok) {
        setError(t("Password reset email sent. Please check your inbox."));
      } else {
        const data = await response.json();
        setError(data.msg || t("Failed to send password reset email."));
      }
    } catch (err) {
      console.error("Forgot Password error:", err);
      setError(t("An error occurred. Please try again later."));
    }
  };


  const sendMessage = async () => {
    try {
      console.log("Sending message to:", phoneNumber);
      let messageOf = `${t("Welcome to SixKeys")}\n`;

      if (role === "admin") {
        messageOf += `${t("Your organization code is:")} ${generatedCode}\n`;
      }

      messageOf += t("Please check your email to verify your account, then log in.");

      const params = new URLSearchParams({
        phoneNumber: phoneNumber,
        message: messageOf,
      }).toString();

      const response = await fetch(`${BASE_URL}/api/messages/send-message?${params}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        }
      });

      const result = await response.json();

      if (response.ok && result.success) {
        setEmailSentMessages(prev => [...prev, `${t("Message sent to")}: ${phoneNumber}`]);
      } else {
        // Display the error message from the backend
        setEmailSentMessages(prev => [
          ...prev,
          `${t("Failed to send to")}: ${phoneNumber}`,
          `Error: ${result.message || "Unknown error"}`
        ]);
      }
    } catch (error) {
      console.error("Frontend error:", error);
      setEmailSentMessages(prev => [
        ...prev,
        `${t("Error sending to")}: ${phoneNumber}`,
        `Details: ${error.message || "Unknown error"}`
      ]);
    }

  };


  // Function to send a message (e.g., SMS) using the backend API
  useEffect(() => {
    if (showPopup) {

      if (role === "admin") {
        sendMessage();
      }

      const timer = setTimeout(() => {
        window.location.reload(); // Force page refresh after 5 seconds
      }, 10000);

      return () => clearTimeout(timer); // Cleanup
    }
  }, [showPopup]);


  return (
    <div className="entire">
      {error && <div className="form-message">{error}</div>}

      <div className="text-and-login">
        <div className="logo-section">
          <img
            src="/Pictures/logo_transparent.png"
            alt="Six Keys Logo"
            className="login-logo"
          />
          <p className="tagline">
            {t("Leading Businesses to")} <strong>{t("Magical")}</strong>{" "}
            {t("Growth")}
          </p>
          <h1></h1>
        </div>

        <div className="login-container">
          <div className="content-box">
            <div className="tabs">
              <button
                className={`tab ${activeTab === 0 ? "active-tab" : ""}`}
                onClick={() => handleTabChange(0)}
              >
                {t("Sign Up")}
              </button>
              <button
                className={`tab ${activeTab === 1 ? "active-tab" : ""}`}
                onClick={() => handleTabChange(1)}
              >
                {t("Login")}
              </button>
            </div>

            {forgotPassword ? (
              <form onSubmit={handleForgotPassword} className="form-box">
                <input
                  type="email"
                  required
                  placeholder={t("Enter your email")}
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  className="input_field_login"
                />
                <button type="submit" className="submit-button">
                  {t("Send Reset Link")}
                </button>
                <button
                  type="button"
                  className="back-button"
                  onClick={() => setForgotPassword(false)}
                >
                  {t("Back to Login")}
                </button>
                {error && <p className="error-text">{error}</p>}
              </form>
            ) : activeTab === 0 ? (
              <form onSubmit={handleSignUpSubmit} className="form-box">
                {!isSecondStep ? (
                  <Fragment>
                    <input
                      type="text"
                      required
                      placeholder={t("Full Name")}
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      className="input_field_login"
                    />
                    <input
                      type="email"
                      required
                      placeholder={t("Email")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="input_field_login"
                    />
                    <input
                      type="password"
                      required
                      placeholder={t("Password")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="input_field_login"
                    />
                    <select
                      required
                      value={role}
                      onChange={handleRoleChange}
                      className="input_field_select"
                    >
                      <option value="employee">{t("Employee")}</option>
                      <option value="admin">{t("Admin")}</option>
                    </select>
                    {error && <p className="error-text">{error}</p>}
                    <button type="submit" className="submit-button">
                      {t("Continue")}
                    </button>
                  </Fragment>
                ) : (
                  <Fragment>
                    <input
                      type="text"
                      required
                      placeholder={t("Business Field")}
                      value={businessField}
                      onChange={(e) => setBusinessField(e.target.value)}
                      className="input_field_login"
                    />
                    <input
                      type="date"
                      required
                      placeholder={t("Business Establishment Date")}
                      value={establishmentDate}
                      onChange={(e) => setEstablishmentDate(e.target.value)}
                      className="input_field_login_date"
                      id="input_field_login_date"
                    />
                    <select
                      required
                      value={companySize}
                      onChange={(e) => setCompanySize(e.target.value)}
                      className="input_field_select"
                    >
                      <option value="">
                        {t("How large is your company?")}
                      </option>
                      <option value="Just me">{t("Just me")}</option>
                      <option value="1-5">1-5</option>
                      <option value="5-25">5-25</option>
                      <option value="25-100">25-100</option>
                      <option value="250-1000">250-1000</option>
                      <option value="1000+">1000+</option>
                      <option value="Prefer not to share">
                        {t("Prefer not to share")}
                      </option>
                    </select>

                    {role === "admin" ? (
                      <>
                        <input
                          type="text"
                          required
                          placeholder={t("Organization Name")}
                          value={organizationName}
                          onChange={(e) => setOrganizationName(e.target.value)}
                          className="input_field_login"
                        />
                        {/* Phone Number input with region selector for admin */}
                        <div className="phone-input-container">
                          <select
                            value={phoneRegion}
                            onChange={(e) => setPhoneRegion(e.target.value)}
                            className="phone-region-select"
                          >
                            {regionOptions.map((region) => (
                              <option key={region.code} value={region.code}>
                                {region.code} ({region.name})
                              </option>
                            ))}
                          </select>
                          <input
                            type="tel"
                            required
                            placeholder={t("Phone Number")}
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className="phone-number-input"
                          />
                        </div>
                      </>
                    ) : (
                      <input
                        type="text"
                        required
                        placeholder={t("Organization Code")}
                        value={organizationCode}
                        onChange={(e) => setOrganizationCode(e.target.value)}
                        className="input_field_login"
                      />
                    )}

                    <div className="checkbox-section">
                      <input type="checkbox" id="terms" required />
                      <label htmlFor="terms">
                        {t("By proceeding, you agree to the")}{" "}
                        <Link to="/terms">
                          {t("Terms and Conditions")} {t("and")}{" "}
                          {t("Privacy Policy")}
                        </Link>
                      </label>
                    </div>

                    <div className="button-group">
                      <button
                        type="button"
                        className="back-button"
                        onClick={() => setIsSecondStep(false)}
                      >
                        {t("Back")}
                      </button>
                      <button type="submit" className="submit-button">
                        {t("Sign Up")}
                      </button>
                    </div>
                  </Fragment>
                )}
              </form>
            ) : (
              <form onSubmit={handleLoginSubmit} className="form-box">
                <input
                  type="text"
                  required
                  placeholder={t("Username")}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="input_field_login"
                />
                <input
                  type="password"
                  required
                  placeholder={t("Password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input_field_login"
                />
                {error && <p className="error-text">{error}</p>}
                <button type="submit" className="submit-button">
                  {t("Login")}
                </button>
                <button
                  type="button"
                  className="forgot-password-button"
                  onClick={() => setForgotPassword(true)}
                >
                  {t("Forgot Password?")}
                </button>
              </form>
            )}
          </div>

          <div className="side-title">
            <h4 className="h4_login">
              {t("We help businesses grow faster and better")}
            </h4>
          </div>
        </div>
      </div>

      {/* Registration successful popup */}
      {
        showPopup && (
          <div className="popup-overlay">
            <div className="popup-content">
              <h3>{t("Registration Successful")}</h3>
              {/* {role === "admin" && (
                <div>
                  <p>{t("Please verify your mail, come back, and log in")}</p>
                  <div className="copy-code-container">
                    <strong>{generatedCode}</strong>
                    <button
                      className="copy-button"
                      onClick={() => navigator.clipboard.writeText(generatedCode)}
                      title={t("Copy to clipboard")}
                    >
                      📋
                    </button>
                  </div>
                </div>
              )} */}
              <p>
                {t(
                  "Please verify your mail, come back, and log in. The window will close himself"
                )}
              </p>
              {/* <button className="close-popup-button" onClick={closePopup}>
                {t("Go to Login")}
              </button> */}
            </div>
          </div>
        )
      }

      {/* Payment popup (for admin) */}
      {
        showPaymentPopup && (
          <div className="popup2-overlay">
            <div className="popup2-content">
              <h3>{t("Complete Payment")}</h3>
              <PaymentPage />
              <button
                className="close-popup2-button"
                onClick={() => setShowPaymentPopup(false)}
              >
                {t("Close")}
              </button>
            </div>
          </div>
        )
      }
    </div >
  );
}

export default LoginSignUp;