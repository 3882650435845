// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App.js';
// import { Auth0Provider } from "@auth0/auth0-react";

// // Load environment variables from .env
// const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
// const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
// const AUTH0_REDIRECT_URI = window.location.origin; // Redirect user to the same site after login

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <React.StrictMode>
//         <Auth0Provider
//             domain={AUTH0_DOMAIN}
//             clientId={AUTH0_CLIENT_ID}
//             authorizationParams={{ redirect_uri: AUTH0_REDIRECT_URI }}
//         >
//             <App />
//         </Auth0Provider>
//     </React.StrictMode>
// );

// // Register the service worker
// if ('serviceWorker' in navigator) {
//     window.addEventListener('load', () => {
//         navigator.serviceWorker
//             .register(`${process.env.PUBLIC_URL}/service-worker.js`)
//             .then((registration) => {
//                 console.log('Service Worker registered with scope:', registration.scope);
//             })
//             .catch((error) => {
//                 console.error('Service Worker registration failed:', error);
//             });
//     });
// }

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// Register the service worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(`${process.env.PUBLIC_URL}/service-worker.js`)
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }
